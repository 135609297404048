import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import { ReactDatePickerProps } from 'react-datepicker';
import dynamic from 'next/dynamic';
import classNames from 'classnames/bind';

import { logAmplitudeWebClick } from 'utils/amplitude';

import AppIcon from 'components/AppIcon/AppIcon';

import styles from './styles/AppInputDatePicker.module.scss';

const DatePicker = dynamic(() => import('react-datepicker'), {
  ssr: false,
});

const cx = classNames.bind(styles);

export interface AppInputDatePickerProps
  extends Omit<ReactDatePickerProps, 'onChange'> {
  label?: string;

  hideErrorMessage?: boolean;
  error?: string | null;
  name: string;
  // TODO 38317 requiredForm is a intermediate prop as we migrate to AppForm
  requiredForm?: boolean;
  onChange: (dates: [Date | null, Date | null]) => void;
  size?: 'medium' | 'large';
  unavailableDates?: Date[];
}

export const AppInputDatePicker: React.FC<AppInputDatePickerProps> = ({
  className,
  error,
  hideErrorMessage = false,
  label,
  name,
  selected,
  onChange,
  onBlur,
  disabled,
  requiredForm,
  monthsShown = 2,
  size = 'medium',
  unavailableDates,
  ...props
}: AppInputDatePickerProps) => {
  const inputClassNames = cx({
    'date-picker__input-field': true,
    'date-picker__input-field--has-error': error,
  });

  const labelClassNames = cx({
    'date-picker__input-label': true,
    'date-picker__input-label--disabled': disabled,
    'date-picker__input-label--required': requiredForm,
  });

  const classNames = cx({
    'date-picker': true,
    'date-picker--large': size === 'large',
  });

  return (
    <div
      className={`${classNames} ${className}`}
      onClick={() => {
        logAmplitudeWebClick('date selected', 'date-picker');
      }}
    >
      <label className={labelClassNames}>{label}</label>
      <DatePicker
        className={`${inputClassNames}`}
        name={name}
        selected={selected}
        onChange={
          onChange as (
            date: Date | [Date, Date] | null,
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            event: React.SyntheticEvent<any, Event> | undefined
          ) => void
        }
        onBlur={onBlur}
        monthsShown={monthsShown}
        disabled={disabled}
        nextMonthButtonLabel={
          <AppIcon
            icon={'chevron_right'}
            classNameWrapper={styles['date-picker__navigation-icon']}
          />
        }
        previousMonthButtonLabel={
          <AppIcon
            icon={'chevron_left'}
            classNameWrapper={styles['date-picker__navigation-icon']}
          />
        }
        showDisabledMonthNavigation // This avoids having the previous month button suddenly appear when a date is selected when using minDate (https://github.com/Hacker0x01/react-datepicker/issues/3457)
        autoComplete="off"
        highlightDates={unavailableDates}
        {...props}
      />
      {error && !hideErrorMessage && (
        <span className={styles['date-picker__error-message']}>{error}</span>
      )}
    </div>
  );
};

export default AppInputDatePicker;
